<template>
  <div class="pbf" v-if="props.user">

     <UserTabActivity
      :user-id="props.user.id"
      :inurl="'/users/' + props.id + '/activity'"      
      />

  </div>
</template>

<script setup lang="ts">

import type { User } from '@/types/user'

interface Props {
  user: User;
  id: number;
}

const props = defineProps<Props>();

</script>

<style scoped>
 

</style>

